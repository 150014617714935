@import "abstracts/_abstracts";
.AppNavigationItem {
	$block: &;
	position: relative;
	margin: 0;
	font-size: 1rem;
	line-height: 1.5;

	@include media('<=sm') {
		margin: 0;
		font-size: .8rem;
		border-bottom: 1px solid var(--colorOutlines);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		color: var(--colorText);

		&:last-child {
			border-bottom: none;
		}
	}

	&:first-child {
		margin-left: 0;
	}

	&-frame {
		display: flex;
		align-items: center;
	}

	&-opener {
		@include media('<=sm') {
			display: flex;
			margin-left: .5em;

			svg {
				width: 12px;
				height: auto;
				transition: var(--animationBase);
			}

			&.is-opened svg {
				transform: rotate(-180deg);
			}
		}
	}

	&-link {
		color: inherit;
		text-decoration: none;
		font-weight: 400;
		transition: all .25s ease-in-out;

		@include media('<=sm') {
			display: block;
			padding: .8em 0;
			flex: 1;
		}

		&.is-active, &:hover, &:focus {
			color: var(--colorBrand);
			text-decoration: none;
		}

		&.is-active {
			@include media('>sm') {
				border: 1px solid;
				padding: 0.75rem 1rem;
				border-radius: 1.5rem;
				font-weight: 600;
			}
		}
	}

	&-submenu {
		position: absolute;
		left: 0;
		top: calc(100% + .8rem);
		transition: var(--animationBase);
		transform-origin: top left;
		transform: scale(0);
		opacity: 0;
		box-shadow: 0 0 20px #0000003b;
		width: 230px;
		z-index: var(--layerDropdownZIndex);
		border-top: 2px solid var(--colorBrand);
		@include media('<=sm') {
			position: static;
			box-shadow: none;
			opacity: 0;
			max-height: 0;
			transform: rotateX(90deg);
			overflow: hidden;
			width: 100%;
			border-top: 1px dashed var(--colorOutlines)
		}

		&:before {
			content: '▲';
			display: block;
			position: absolute;
			bottom: 100%;
			width: 100%;
			text-align: left;
			padding-left: 1em;
			color: var(--colorBrand);
			line-height: 1;
			padding-top: 0.4em;
		}

		#{$block}:hover & {
			@include media('>sm') {
				transform: scale(1);
				opacity: 1;
			}
		}

		&.is-opened {
			@include media('<=sm') {
				opacity: 1;
				max-height: 1000px;
				transform: rotateX(0deg);
			}
		}

		#{$block} {
			background: #fff;
			width: 100%;
			border-bottom: 1px solid var(--colorOutlines);
			margin: 0;

			&:last-child {
				border-bottom: none;
			}

			&-link {
				display: block;
				padding: 10px;
				font-size: var(--textMd);
				color: var(--colorText);

				&.is-active, &:hover, &:focus {
					color: var(--colorBrand);
					text-decoration: none;
				}

				@include media('<=sm') {
					padding: 1em 0 1em 1.5em;
					font-weight: 400;
					font-size: .9em;
				}
			}
		}
	}
}